.plan-card {
    /* background-color: #0a1f4474;  */
    border-radius: 15px;
    padding: 50px 20px;
    color: #fff; /* White text color */
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .plan-card h2 {

    font-size: 30px;
    font-weight: bold;
  }
  
  .subtitle {
    font-size: 14px;
    color: #8fa6bb;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .features {
    list-style-type: none;
    padding: 0;
    margin:0 auto;
  }
 
  
  .features li {
    font-size: 16px;
    margin: 10px 0;
    display: flex;
    gap:10px;
  }
  .features>li>span{
    background-color: white;
    color: var(--blue-900);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .get-started-btn {
    background-color: var(--blue-700);
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 50px;
  }
  
  .get-started-btn:hover {
    background-color: var(--blue-800);;
  }
  