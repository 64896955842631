.about-top-left{
   margin-top: 0; 
}
.about-top-left>h1{
    text-align: left;
}
.about-top-left>p{
    color: white;
    font-size: 19px;
}

.about-top-right{
  
}

.features-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
    color:white;
  }
  
  .features-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    gap:30px;
  }
  
  .feature-item {
    flex: 1 1 45%;
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
  }
  .feature-item p {
   text-align: left;
  }
  
  .icon {
    color: #2a9d8f;
    margin-right: 10px;
    font-size: 24px;
  }
  

  @media screen and (max-width: 768px) {

    .features-grid {
      flex-direction: column;
      }
      .feature-item{
        width: 100%;
        display: flex;
      }



  }