.login-contatiner{
  color: var(--blue-50);
  display: flex;
  height: 100vh;

}
.login-contatiner>.left{
  width: 30%;
  padding: 30px 10px;
  overflow-y:auto;
 


}
.login-contatiner>.right{
 
 width: 70%;
 padding: 30px;
 background-image: url("../images/trade.jpg");
 background-repeat: no-repeat;
 background-size: cover;

}


.login {
   width: 100%;
   padding: 50px 35px;
    max-width: 450px;
    color: white;
   
  }
  .login>form{
    margin:auto
  }
  
  .page-head {
    text-align: center;
    margin-bottom: 20px;
  }
  
 
  
  .remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .login-navigator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px auto 30px  auto;
  }

  .new-password-change-div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
 
  

  .cancel-password-change{
    margin-top: 20px;
  }
  @media screen and (max-width:750px) {
    .login-contatiner{
      flex-direction: column;
    }
    .login-contatiner>.left{
       
      width: 100%;
    }
    .login-contatiner>.right{
       
      width: 100%;
    }
    .login-contatiner>.right>img{
       
      width: 100%;
      height: 300px;
    }
   
    .login {
      padding: 40px;
    }
  
    input {
      font-size: 14px;
    }
  
    .error {
      font-size: 12px;
    }
  }
  

  
  