.DashboardInvestments{
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.investment-content-container{
    display: flex;
    justify-content: space-between;
    gap: 18px;
    width:100%
    
    
}
.investment-content-container.left{
    width: 70%;
}
/* .investment-content-container.right{
    width: 30%;
    background-color: brown;
    
} */

.investmentcheckout{
  
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 18px;
    gap: 18px;
}


.invest-form-container{
    padding: 20px;
    /* max-width: 500px; */
    /* margin: auto; */
    color: white;
    border-radius: 8px;
}
.invest-form-container>form{
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 8px;
}

.form-group select{
 
    color : var(--blue-600)
}

.quick-amounts{
    margin-top: 20px;

    display: flex;
    flex-wrap: wrap;
    gap:18px;

}

.checkout-segment{
    /* border-top: 1px solid white; */
    border-bottom: 1px dashed var(--blue-400);
    padding: 20px 0;
    color: white;

}
.checkout-segment>div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.checkout-segment>div>div{
   width: 50%;

}
.investvalue{
    color:var(--blue-600);
    font-size: 13px;
    font-weight: bold;
    
}

.start-investment-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    color: white;
    align-items: center;
}
.start-investment-container>img{
    max-width: 500px;
    
}
.investment-list-container{
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.investment-cards-container{
    display: flex;
    flex-direction: column;
    gap: 18px;
}

@media screen and (max-width: 768px){

    .investment-content-container{
        flex-direction: column;
        
    }
    .investment-content-container>div{
       width: 100%;

    }

    .start-investment-container>img{
        width:80%;
        
    }

}