.component4{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:30px;
    background-color: var(--backgroundLight);
    padding:20px;
    border-radius: 20px;
}
.component4>p{
    color: var(--textColor1);
    text-align: left;
}
.reviewer-details{
    display: flex;
    gap:10px;
    text-align: left;
    
}
.reviewer-details>img{
   width: 70px;
   height: 70px;
   border-radius: 50%;

}