
.dashboard-home{
    display: flex;
    flex-direction: column;
    gap:16px;
    color:white;
}
.dashboard-intro{
    border-radius: 20px;
}

.welcome-div{
    background-color: var(--blue-950);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left:18px;
    border-radius: 18px;
}
.welcome-div>p{
    font-size: 25px;
}
.welcome-div>img{
   width: 50%;
   height: 200px;
   border-top-right-radius: 18px;
   border-bottom-right-radius: 18px;
}


.first-inner{
    background-color: var(--blue-950);
    justify-content: space-between;
    padding:18px;
    border-radius: 18px;
}
.second-inner{
    background-color: var(--blue-1000);
    padding:5px;
    border-radius: 18px;
}

.welcome-balance{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.welcome-balance>div{
    display: flex;
    gap: 12px;
    align-items: center;
}
.welcome-balance>p{
   font-size: 30px;
   font-weight: bold;
}

.welcome-withdrawal-button{
    display: flex;
    gap:20px;
    margin-top: 20px;
}

.dash-header{
    color:white;
    font-size: 20px;
}
.dash-description{
    color:var(--blue-300);
    font-size: 15px;
}

.dash-home-controls{
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
    gap:12px;
  
}
.dash-home-controls>div{
   display: flex;
   flex-direction: column;
   gap:12px;
  
   background-color: var(--blue-900);
   padding:18px;
   border-radius:18px;
}
.dash-home-controls>div>img{
   width: 80px;
   height: 80px;
   border-radius: 50%;
   margin: 0 auto;
}



@media screen and (max-width: 768px) {

    .welcome-div{
      flex-direction: column-reverse;
      padding: 0;
     }
    .welcome-div>p{
      width: 90%;
      margin: 20px 0;
     }
     .welcome-div>img{
        width: 100%;
        height: 100px;
       border: none;
       border-top-left-radius: 18px;
       border-bottom-left-radius: 18px;
     }
     
}