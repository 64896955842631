.admin-dashboard{

    display:  flex;
    justify-content: space-between;
}
.admin-dashboard .left{
    width: 20%;
    padding:20px;
}

.user-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:20px; 
}
.user-item-container{
    border:2px solid black;
    width: 50%;
    margin:0 auto;
    padding: 20px;
}

.action-buttons{
    display: flex;
    flex-direction: column;
    gap:15px;
}

.admin-dashboard>.right{
    width: 80%;
    padding:20px;
}

.right-container{
    display: flex;
    flex-direction: column;
    gap:40px;
}

.search-container{
    display: flex;
    justify-content: space-between;
    gap:20px;
    width: 60%;
    margin: 0 auto;
}
.search-container>input{
    color: black;
}

.search-result-container{
    display: flex;
    gap:20px;
    flex-direction: column;
}
.search-result-container>div{
   border: 2px solid black;
   padding:20px;
   width: 60%;
   margin: 0 auto;
   border-radius: 20px;
}


@media screen and (max-width: 768px) {

.admin-dashboard{

    flex-direction: column;
}

.admin-dashboard .left{

display: flex;
gap:10px;
width: 100%;

}
.admin-dashboard .left>p{

width: 50%;
padding: 20px;
background-color: burlywood;
border-radius: 10px;
}

.admin-dashboard>.right{
    width: 100%;
    padding:10px;  
}
.user-item-container{
  
    width: 90%;
   
}

.search-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:10px;
    width: 80%;
    margin: 0 auto;
}

.search-result-container>div{
    border: 2px solid black;
    padding:20px;
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
 }


}