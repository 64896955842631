.Dashboard{
    display: flex;
    justify-content: space-between;
   
} 

.DashboardMenu{
    background-color: var(--blue-950);
    color:var(--blue-50);
   
   /* border-right: 14px solid var(--blue-900); */

}
.DashboardMenu>ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin:35px 0;
    justify-content: center;
}
.DashboardMenu>ul>li{
   padding: 20px;
   display: flex;
   gap:15px;
   align-items: center;
   color:white;

}
.DashboardMenu>ul>li:active{
  background-color: var(--blue-950);
}
.DashboardMenu>ul>li>i{
   font-size: 18px;
   width:10%;
  
}
.DashboardMenu>ul>li>p{
   font-size: 18px;
   text-align: left;
}

.selected {
  color:var(--blue-900);
  background-color: var(--blue-500);
}
.DashboardMenu-Tab{

background-color:var(--blue-900);;
flex: 1;
padding: 16px;
min-height: 800px;

}

.menu-toggle{
   display: flex;
   gap:20px;
   align-items: center;
   padding: 25px;
   /* background-color: rgba(var(--blue-500), 0.2); */
   background-color: var(--blue-600);
   margin-bottom: 16px;
   font-weight: bold;
   font-size: 20px;
   border-radius: 50px;
   color:var(--blue-50);
}
.logout-button{
   margin-top: 60px;
   display: flex;
   gap :10px;
   align-items: center;
}

.menu-balance{
   padding: 20px;
   background-color: var(--blue-500);

}
.menu-balance>h3{
   font-size: 18px;
   font-weight: bold;

}


@media screen and (max-width: 768px) {
   .Dashboard{
    flex-direction: column;
    position: relative;
   }
   .DashboardMenu{
    display: none;
   }
   .MobileMenu{
    display: block;
    position: absolute;
    top: 100px;
    z-index: 999;
    background-color: var(--headerColor3); 
    color:white;
    padding:15px;
    
    font-size: 20px;
    width: 80%;
    height: 100%;
 
   }
   .MobileMenu>ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
   }
   .MobileMenu>ul>li{
      padding: 20px;
      display: flex;
      gap:15px;
      align-items: center;
   }

   .menu-toggle{
     
     
      
   }

   .menu-balance{
      color: var(--blue-900);
   
   }
  }
  