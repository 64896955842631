.crypto-prices {
    padding: 20px;
    background-color: var(--blue-950);
    color: white;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid white;
}

th {
    background-color: var(--blue-1000);
}




